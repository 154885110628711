import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'


export const ApiResponse = [
  {
    label: 'MENU',
    isHeader: true
  },
  {
    id: 'cockpit',
    label: 'COCKPIT',
    icon: 'ri-dashboard-2-line',
    link: '/iframe?url=#'
  },
  {
    id: 'orders',
    label: 'ORDERS',
    costumIcon: 'orderIcon',
    icon: 'ri-apps-2-line',
    subItems: [
      {
        id: 'b2b',
        label: 'B2B',
        link: '/iframe?url=orders#b2b',
        parentId: 'orders'
      },
      {
        id: 'b2c',
        label: 'B2C',
        link: '/iframe?url=orders#b2c',
        parentId: 'orders'
      },
      {
        id: 'b2b2c',
        label: 'B2B2C',
        link: '/iframe?url=orders#b2b2c',
        parentId: 'orders'
      },
      {
        id: 'smartOrders',
        label: 'Smart Orders',
        link: '/smartOrders',
        parentId: 'orders'
      }
    ]
  },
  {
    id: 'suppliers',
    label: 'SUPPLIERS',
    icon: 'las la-cubes',
    subItems: [
      {
        id: 'open',
        label: 'OPEN_PO',
        link: '/iframe?url=suppliers#open',
        parentId: 'suppliers'
      },
      {
        id: 'process',
        label: 'PROCESSING_PO',
        link: '/iframe?url=suppliers#process',
        parentId: 'suppliers'
      },
      {
        id: 'so',
        label: 'SUPPLIERS_ORDERS',
        link: '/iframe?url=suppliers#so',
        parentId: 'suppliers'
      },
      {
        id: 'suppliers',
        label: 'SUPPLIERS',
        link: '/iframe?url=suppliers#suppliers',
        parentId: 'suppliers'
      }
    ]
  },
  {
    id: 'products',
    label: 'ARTICLES',
    costumIcon: 'productsIcon',
    icon: 'bx bx-cube',
    subItems: [
      {
        id: 'b2b',
        label: 'B2B',
        link: '/iframe?url=products#b2b',
        parentId: 'products'
      },
      {
        id: 'b2c',
        label: 'B2C',
        link: '/iframe?url=products#b2c',
        parentId: 'products'
      },
      {
        id: 'materials',
        label: 'RAW_MATERIALS',
        link: '/iframe?url=products#materials',
        parentId: 'products'
      },
      {
        id: 'services',
        label: 'SERVICES',
        link: '/iframe?url=products#services',
        parentId: 'products'
      }
    ]
  },
  {
    id: 'fitting',
    label: 'FITTING',
    icon: 'ri-pencil-ruler-2-line',
    subItems: [
      {
        id: 'calendar',
        label: 'SCHEDULE',
        link: '/iframe?url=fitting#calendar',
        parentId: 'fitting'
      },
      {
        id: 'centers',
        label: 'FITTING_CENTERS',
        link: '/iframe?url=fitting#centers',
        parentId: 'fitting'
      },
      {
        id: 'configs',
        label: 'CONFIG',
        link: '/iframe?url=fitting#configs',
        parentId: 'fitting'
      }
    ]
  },
  {
    id: 'logistics',
    label: 'LOGISTICS',
    costumIcon: 'logisticsIcon',
    icon: 'mdi mdi-truck-fast-outline',
    subItems: [
      {
        id: 'over',
        label: 'STOCK',
        link: '/iframe?url=logistics#over',
        parentId: 'logistics'
      },
      {
        id: 'arrivals',
        label: 'ARRIVALS',
        link: '/iframe?url=logistics#arrivals',
        parentId: 'logistics'
      },
      {
        id: 'depart',
        label: 'DEPARTURES',
        link: '/iframe?url=logistics#depart',
        parentId: 'logistics'
      },
      {
        id: 'history',
        label: 'HISTORY',
        link: '/iframe?url=logistics#main',
        parentId: 'logistics'
      },
      {
        id: 'config',
        label: 'CONFIG',
        parentId: 'logistics',
        childItems: [
          {
            id: 'config',
            label: 'WAREHOUSES',
            link: '/iframe?url=logistics#config',
            parentId: 'configuration'
          },
          {
            id: 'shipping_rate',
            label: 'SHIPPING_RATE',
            link: '/iframe?url=logistics/rates',
            parentId: 'configuration'
          },
          {
            id: 'carriers',
            label: 'CARRIERS',
            link: '/iframe?url=logistics/carriers',
            parentId: 'configuration'
          },
          {
            id: 'Inventory',
            label: 'INVENTORY',
            link: '/iframe?url=logistics/maintenance/inventory',
            parentId: 'configuration'
          }
        ]
      }
    ]
  },
  {
    id: 'client',
    label: 'CLIENTS',
    icon: 'ri-account-circle-line',
    link: '/iframe?url=clients'
  },
  {
    id: 'reports',
    label: 'REPORTS',
    icon: 'ri-layout-grid-line',
    subItems: [
      {
        id: 'sales',
        label: 'SALES',
        link: '/iframe?url=reports#sales',
        parentId: 'reports'
      },
      {
        id: 'logistics',
        label: 'LOGISTICS',
        link: '/iframe?url=reports#logistics',
        parentId: 'reports'
      },
      {
        id: 'marketing',
        label: 'MARKETING',
        link: '/iframe?url=reports#marketing',
        parentId: 'reports'
      },
      {
        id: 'orders',
        label: 'ORDERS',
        link: '/iframe?url=reports#orders',
        parentId: 'reports'
      },
      {
        id: 'employees',
        label: 'EMPLOYEES',
        link: '/iframe?url=reports#employees',
        parentId: 'reports'
      },
      {
        id: 'functionalities',
        label: 'FUNCTIONALITIES',
        link: '/iframe?url=reports#functionalities',
        parentId: 'reports'
      },
      {
        id: 'internalOperations',
        label: 'INTERNAL_OPERATIONS',
        link: '/iframe?url=reports#internalOperations',
        parentId: 'reports'
      }
    ]
  }
  // {
  //   id: 'transfers',
  //   label: 'Transfers',
  //   icon: 'ri-account-circle-line',
  //   link: '/transfers',
  // },
]

const Navdata = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const urlQueryParams = new URLSearchParams(location.search)
  const urlParam = urlQueryParams.get('url')
  const hash = location?.hash.slice(1)

  const decodeUrlIfNeeded = (url) => {
    if (url && url.includes('%2F')) {
      return decodeURIComponent(url)
    }
    return url
  }

  const decodedUrlParam = decodeUrlIfNeeded(urlParam)

  const [iscurrentState, setIscurrentState] = useState(decodedUrlParam ? decodedUrlParam.split('/')[0] : '')
  const [iscurrentSubItemState, setIscurrentSubItemState] = useState(hash || '')
  const [iscurrentChildItemState, setIscurrentChildItemState] = useState('')
  const [activeParentId, setActiveParentId] = useState('')

  const [items, setItems] = useState({
    items: [],
    currentState: iscurrentState,
    subItemCurrentState: iscurrentSubItemState,
    childItemCurrentState: iscurrentChildItemState,
    activeParentId: activeParentId
  })

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute('subitems')) {
      const ul = document.getElementById('two-column-menu')
      const iconItems = ul?.querySelectorAll('.nav-icon.active')
      const activeIconItems = iconItems ? [...iconItems] : []
      activeIconItems.forEach((item) => {
        item.classList.remove('active')
        var id = item.getAttribute('subitems')
        if (document.getElementById(id)) document.getElementById(id).classList.remove('Cockpit')
      })
    }
  }

  const handleMenuItems = () => {
    const tempArray = []

    ApiResponse.map((api) => {
      let menuItem = {
        id: api.id,
        costumIcon: api.costumIcon,
        isHeader: api.isHeader,
        label: api.label,
        icon: api.icon,
        link: api.link,
        click: (e) => {
          if (e) e.preventDefault()

          if (api.link) {
            if (api.link.includes('/iframe?url=')) {
              window.history.replaceState(null, '', api.link)
              window.dispatchEvent(new PopStateEvent('popstate'))
            } else {
              navigate(api.link)
            }
            setIscurrentState(api.id)
            setActiveParentId(api.id)
            setIscurrentSubItemState('')
            setIscurrentChildItemState('')
          } else if (api.subItems) {
            setIscurrentState(iscurrentState === api.id ? '' : api.id)
            setActiveParentId(iscurrentState === api.id ? '' : api.id)
            setIscurrentSubItemState('')
            setIscurrentChildItemState('')
          }
          if (e) updateIconSidebar(e)
        },
        stateVariables: iscurrentState === api.id,
      }

      if (api?.subItems && api?.subItems?.length > 0) {
        menuItem = {
          ...menuItem,
          subItems: api.subItems.map((subItem) => ({
            id: subItem.id,
            label: subItem.label,
            link: subItem.link,
            parentId: subItem.parentId,
            click: (e) => {
              if (e) e.preventDefault()

              if (subItem.link) {
                if (subItem.link.includes('/iframe?url=')) {
                  window.history.replaceState(null, '', subItem.link)
                  window.dispatchEvent(new PopStateEvent('popstate'))
                } else {
                  navigate(subItem.link)
                }
              }
              setIscurrentState(api.id)
              setActiveParentId(api.id)
              setIscurrentSubItemState(subItem.id)
              setIscurrentChildItemState('')
              if (e) updateIconSidebar(e)
            },

            stateVariables: iscurrentSubItemState === subItem.id && activeParentId === api.id,
            childItems: subItem.childItems
              ? subItem.childItems.map((childItem) => ({
                id: childItem.id,
                label: childItem.label,
                link: childItem.link,
                parentId: childItem.parentId,
                click: (e) => {
                  if (e) e.preventDefault()

                  if (childItem.link) {
                    if (childItem.link.includes('/iframe?url=')) {
                      window.history.replaceState(null, '', childItem.link)
                      window.dispatchEvent(new PopStateEvent('popstate'))
                    } else {
                      navigate(childItem.link)
                    }
                  }
                  setIscurrentState(api.id)
                  setActiveParentId(api.id)
                  setIscurrentSubItemState(subItem.id)
                  setIscurrentChildItemState(childItem.id)
                  if (e) updateIconSidebar(e)
                },
                stateVariables: iscurrentChildItemState === childItem.id && activeParentId === api.id,
              }))
              : undefined,
          })),
        }
      }

      tempArray.push(menuItem)
    })

    setItems({
      items: tempArray,
      currentState: iscurrentState,
      subItemCurrentState: iscurrentSubItemState,
      childItemCurrentState: iscurrentChildItemState,
      activeParentId: activeParentId
    })
  }

  useEffect(() => {
    handleMenuItems()
  }, [iscurrentState, iscurrentSubItemState, iscurrentChildItemState, activeParentId])

  useEffect(() => {
    if (decodedUrlParam) {
      const urlParts = decodedUrlParam.split('/')
      const mainSection = urlParts[0]


      const isOrderId = (id) => /^(ATX|ZGA)\d+/.test(id)
      const hasOrderId = urlParts.some(part => isOrderId(part))

      if (hasOrderId) {
        const orderId = urlParts.find(part => isOrderId(part))
        setIscurrentState('orders')
        setActiveParentId('orders')
        setIscurrentSubItemState(orderId.startsWith('ATX') ? 'b2b' : 'b2c')
      } else {
        const mainMenuItem = ApiResponse.find((item) => item.id === mainSection)
        if (mainMenuItem) {
          setIscurrentState(mainSection)
          setActiveParentId(mainSection)
          if (hash) {
            setIscurrentSubItemState(hash)
          } else if (urlParts.length > 1) {
            const subSection = urlParts[1]
            if (mainMenuItem.subItems?.some(sub => sub.id === subSection)) {
              setIscurrentSubItemState(subSection)
            }
          }
        }
      }
    }
  }, [decodedUrlParam, hash])

  return <React.Fragment>{items}</React.Fragment>
}

export default Navdata