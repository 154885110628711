import { ApiResponse } from '../../Layouts/LayoutMenuData'

const extractValidPaths = (items) => {
  let paths = []
  items.forEach((item) => {
    if (item.link && item.link.includes('/iframe?url=')) {
      const url = new URLSearchParams(item.link.split('?')[1]).get('url')
      if (url && url !== '#') {
        const basePath = url.split('#')[0]
        paths.push(basePath)
      }
    }
    if (item.subItems) {
      paths = paths.concat(extractValidPaths(item.subItems))
    }
    if (item.childItems) {
      paths = paths.concat(extractValidPaths(item.childItems))
    }
  })
  return [...new Set(paths)]
}


export const getValidIframePaths = () => {
  return extractValidPaths(ApiResponse)
}


const extractSectionSubsections = () => {
  const sectionMap = {}

  ApiResponse.forEach((item) => {
    if (item.id && !item.isHeader) {
      sectionMap[item.id] = {
        section: item.id,
        subsections: [],
      }

      if (item.subItems) {
        item.subItems.forEach((subItem) => {
          if (subItem.id) {
            sectionMap[item.id].subsections.push(subItem.id)
          }
        })
      }
    }
  })

  return sectionMap
}


const getSectionMap = () => {
  return extractSectionSubsections()
}

const isOrderId = (str) => {
  return /^(ATX|ZGA)\d+/.test(str)
}

export const parseUrlPath = (url) => {
  try {
    const decodedUrl = decodeURIComponent(url)

    if (isOrderId(decodedUrl)) {
      const orderType = decodedUrl.startsWith('ATX') ? 'b2b' : 'b2c'
      return {
        section: 'orders',
        subsection: orderType,
        id: decodedUrl,
        fullPath: decodedUrl,
        isDetailPage: true,
        isOrderDetail: true,
      }
    }

    const parts = decodedUrl.split('/').filter(Boolean)
    const baseSection = parts[0]
    const isDetailPage = parts.length > 1

    let subsection = ''
    let id = null

    if (parts.length > 1) {
      const sectionMap = getSectionMap()
      if (sectionMap[baseSection] && sectionMap[baseSection].subsections.includes(parts[1])) {
        subsection = parts[1]
        id = parts.length > 2 ? parts[parts.length - 1] : null
      } else {
        id = parts[parts.length - 1]

        if (baseSection === 'logistics' && parts[1] && parts[1].includes('waybill-kits')) {
          subsection = 'depart'
        }
      }
    }

    return {
      section: baseSection,
      subsection,
      id,
      fullPath: decodedUrl,
      isDetailPage,
      isOrderDetail: false,
    }
  } catch (error) {
    console.error('Error parsing URL path:', error)
    const parts = url.split('/').filter(Boolean)
    return {
      section: parts[0] || '',
      subsection: '',
      id: parts.length > 1 ? parts[parts.length - 1] : null,
      fullPath: url,
      isDetailPage: parts.length > 1,
      isOrderDetail: false,
    }
  }
}

export const checkIfRouteExistsInParent = (url) => {
  const validPaths = getValidIframePaths()
  const { section, subsection, id, fullPath, isDetailPage, isOrderDetail } = parseUrlPath(url)

  if (isOrderDetail) {
    return {
      exists: true,
      redirectTo: 'orders',
      section: 'orders',
      subsection,
      id,
      fullPath,
      isDetailPage: true,
    }
  }

  const basePathExists = validPaths.some((path) => section === path)

  return {
    exists: basePathExists,
    redirectTo: section,
    section,
    subsection,
    id,
    fullPath,
    isDetailPage,
  }
}



export const formatIframeUrl = (section, fullPath = '', hash = '') => {
  const cleanSection = section.replace(/^\//, '')
  const cleanPath = fullPath.replace(/^\//, '')
  const urlPath = cleanPath || cleanSection
  const hashFragment = hash ? (hash.startsWith('#') ? hash : `#${hash}`) : ''


  return `/iframe?url=${urlPath}${hashFragment}`
}
