import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { checkIfRouteExistsInParent } from '../../infra/utils/route-utils'

const IFrame = () => {
  const iframeRef = useRef(null)
  const [iframeLoaded, setIframeLoaded] = useState(false)
  const authToken = localStorage.getItem('authToken')
  const [currentLanguage, setCurrentLanguage] = useState(() =>
    JSON.parse(localStorage.getItem('I18N_LANGUAGE') || '{}'),
  )
  const location = useLocation()
  const navigate = useNavigate()

  const urlCleanupTimerRef = useRef(null)
  const lastCleanedUrlRef = useRef('')

  const queryParams = new URLSearchParams(location.search)
  const urlParam = queryParams.get('url')
  const hash = location.hash.replace('#', '')

  const cleanUrl = useCallback(() => {
    const currentSearch = window.location.search
    const currentHash = window.location.hash
    const currentFullUrl = window.location.pathname + currentSearch + currentHash

    if (
      currentFullUrl === lastCleanedUrlRef.current ||
      (!currentSearch.includes('languageId=') && !currentSearch.includes('%2F'))
    ) {
      return false
    }

    const params = new URLSearchParams(currentSearch)
    const urlParam = params.get('url')

    if (!urlParam) return false

    const decodedUrl = urlParam.replace(/%2F/g, '/')
    const newUrl = `/iframe?url=${decodedUrl}${currentHash}`

    if (newUrl === currentFullUrl) return false

    window.history.replaceState(null, '', newUrl)
    lastCleanedUrlRef.current = newUrl
    return true
  }, [])

  const startUrlCleanupIfNeeded = useCallback(() => {
    const needsCleaningNow = cleanUrl()

    if (needsCleaningNow && !urlCleanupTimerRef.current) {
      urlCleanupTimerRef.current = setInterval(() => {
        const stillNeedsCleaning = cleanUrl()

        if (!stillNeedsCleaning) {
          clearInterval(urlCleanupTimerRef.current)
          urlCleanupTimerRef.current = null
        }
      }, 100)
    }
  }, [cleanUrl])

  useEffect(() => {
    startUrlCleanupIfNeeded()

    const originalPushState = window.history.pushState
    const originalReplaceState = window.history.replaceState

    window.history.pushState = function (state, title, url) {
      const result = originalPushState.call(this, state, title, url)
      setTimeout(startUrlCleanupIfNeeded, 0)
      return result
    }

    window.history.replaceState = function (state, title, url) {
      const result = originalReplaceState.call(this, state, title, url)
      setTimeout(startUrlCleanupIfNeeded, 0)
      return result
    }

    const handlePopState = () => {
      setTimeout(startUrlCleanupIfNeeded, 0)
    }
    window.addEventListener('popstate', handlePopState)

    return () => {
      if (urlCleanupTimerRef.current) {
        clearInterval(urlCleanupTimerRef.current)
      }
      window.history.pushState = originalPushState
      window.history.replaceState = originalReplaceState
      window.removeEventListener('popstate', handlePopState)
    }
  }, [cleanUrl, startUrlCleanupIfNeeded])

  const adjustIframeSize = useCallback(() => {
    if (iframeRef.current) {
      const windowHeight = window.innerHeight
      const headerHeight = 77
      const iframeHeight = windowHeight - headerHeight
      iframeRef.current.style.height = `${iframeHeight}px`
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', adjustIframeSize)
    adjustIframeSize()
    return () => window.removeEventListener('resize', adjustIframeSize)
  }, [adjustIframeSize])

  const extractRouteInfo = useCallback(
    (url) => {
      if (!url) return { section: '', subsection: '' }

      if (/^(ATX|ZGA)\d+/.test(url)) {
        return {
          section: 'orders',
          subsection: url.startsWith('ATX') ? 'b2b' : 'b2c',
          id: url,
        }
      }

      const parts = url.split('/').filter(Boolean)
      const section = parts[0]
      const isDetailPage = parts.length > 1
      const id = isDetailPage ? parts[parts.length - 1] : null

      if (section === 'logistics' && parts[1] && parts[1].includes('waybill-kits')) {
        return {
          section: 'logistics',
          subsection: 'depart',
          id: parts[2] || null,
        }
      }

      return {
        section,
        subsection: hash || parts[1] || '',
        id,
      }
    },
    [hash],
  )

  const updateSidebarMenu = useCallback(
    (url, hash) => {
      if (!url) return

      const { section, subsection, id } = extractRouteInfo(url)

      window.dispatchEvent(
        new CustomEvent('updateSideMenu', {
          detail: {
            section,
            subSection: subsection || hash || '',
            id,
          },
        }),
      )
    },
    [extractRouteInfo],
  )

  const handleIFrameLink = useCallback(() => {
    const queryParams = new URLSearchParams(location.search)
    const urlParam = queryParams.get('url')

    const baseUrl = urlParam
      ? `${window.env.REACT_APP_IFRAME_PARENT_URL}/${urlParam}`
      : window.env.REACT_APP_IFRAME_PARENT_URL

    const url = new URL(baseUrl)
    url.searchParams.set('languageId', currentLanguage?.id?.toString() || '')

    queryParams.forEach((value, key) => {
      if (key !== 'languageId' && key !== 'url') {
        url.searchParams.set(key, value)
      }
    })

    return `${url.toString()}${location.hash}`
  }, [location.search, location.hash, currentLanguage])

  useEffect(() => {
    const handleLanguageChange = () => {
      const newLanguage = JSON.parse(localStorage.getItem('I18N_LANGUAGE') || '{}')
      setCurrentLanguage(newLanguage)
      if (iframeRef.current) {
        iframeRef.current.src = handleIFrameLink()
      }
    }

    const originalSetItem = localStorage.setItem
    localStorage.setItem = function (key) {
      originalSetItem.apply(this, arguments)
      if (key === 'I18N_LANGUAGE') {
        handleLanguageChange()
      }
    }

    window.addEventListener('storage', (e) => {
      if (e.key === 'I18N_LANGUAGE') {
        handleLanguageChange()
      }
    })

    return () => {
      localStorage.setItem = originalSetItem
      window.removeEventListener('storage', handleLanguageChange)
    }
  }, [handleIFrameLink])

  useEffect(() => {
    if (urlParam) {
      updateSidebarMenu(urlParam, hash)
    }
  }, [urlParam, hash, updateSidebarMenu])

  useEffect(() => {
    const handleMessage = (event) => {
      if (event?.data?.type === 'urlChange' && event?.data?.url) {
        const iframeUrl = event.data.url
        const iframeHash = event.data.hash || ''
        const routeInfo = checkIfRouteExistsInParent(iframeUrl)

        if (routeInfo.exists) {
          const { section, subsection, id } = extractRouteInfo(routeInfo.fullPath)
          const exactUrl = `/iframe?url=${routeInfo.fullPath}${iframeHash}`

          if (
            location.pathname !== '/iframe' ||
            new URLSearchParams(location.search).get('url') !== routeInfo.fullPath ||
            location.hash !== iframeHash
          ) {
            window.history.replaceState(null, '', exactUrl)

            const originalPushState = window.history.pushState
            const originalReplaceState = window.history.replaceState

            window.history.pushState = function (state, title, url) {
              if (url && url.includes('languageId=') && url.includes('/iframe')) {
                return
              }
              return originalPushState.call(this, state, title, url)
            }

            window.history.replaceState = function (state, title, url) {
              if (url && url.includes('languageId=') && url.includes('/iframe')) {
                return
              }
              return originalReplaceState.call(this, state, title, url)
            }

            setTimeout(() => {
              window.history.pushState = originalPushState
              window.history.replaceState = originalReplaceState
            }, 500)

            setTimeout(() => {
              navigate(exactUrl, {
                replace: false,
                state: {
                  section,
                  subsection,
                  id,
                  isDetailPage: routeInfo.isDetailPage,
                  fullPath: routeInfo.fullPath,
                },
              })
            }, 10)
          }

          setTimeout(() => {
            window.dispatchEvent(
              new CustomEvent('updateSideMenu', {
                detail: {
                  section: section || routeInfo.section,
                  subSection: subsection || routeInfo.subsection || iframeHash.replace('#', ''),
                  id: id || routeInfo.id,
                  url: routeInfo.fullPath,
                  hash: iframeHash,
                },
              }),
            )
          }, 50)
        }
      }

      if (event?.data?.message === 'logout') {
        navigate('/logout')
      }
    }

    window.addEventListener('message', handleMessage)
    return () => window.removeEventListener('message', handleMessage)
  }, [location, navigate, extractRouteInfo])

  const sendMessageToIframe = useCallback(() => {
    if (iframeRef.current?.contentWindow) {
      const message = {
        type: 'authentication',
        token: authToken,
        isHeaderVisible: 'false',
      }
      iframeRef.current.contentWindow.postMessage(message, window.env.REACT_APP_IFRAME_PARENT_URL)
    }
  }, [authToken])

  useEffect(() => {
    if (iframeLoaded) {
      sendMessageToIframe()
    }
  }, [iframeLoaded, sendMessageToIframe])

  const handleIframeLoad = useCallback(() => {
    adjustIframeSize()

    try {
      const iframeDocument = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document
      const iframeBody = iframeDocument.body

      setIframeLoaded(true)

      if (urlParam) {
        updateSidebarMenu(urlParam, hash)
      }

      iframeBody.style.overflow = 'auto'
      iframeBody.style.height = '100%'
      iframeBody.style.margin = '0'
      iframeBody.style.padding = '0'

      setTimeout(startUrlCleanupIfNeeded, 100)
    } catch (e) {
      console.log('Error:', e)
    }
  }, [urlParam, hash, updateSidebarMenu, adjustIframeSize, startUrlCleanupIfNeeded])

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: 'calc(100vh - 77px)',
        marginTop: 77,
        overflow: 'hidden',
      }}
    >
      <iframe
        id='iframe'
        ref={iframeRef}
        onLoad={handleIframeLoad}
        src={handleIFrameLink()}
        title='Content iframe'
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          margin: 0,
          padding: 0,
          display: 'block',
          backgroundColor: '#fff',
        }}
      />
    </div>
  )
}

export default IFrame

