import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Collapse } from 'reactstrap'
import { createSelector } from 'reselect'
import Icon from '../../Components/Common/icon'
import withRouter from '../../Components/Common/withRouter'
import navdata from '../LayoutMenuData'

const VerticalLayout = props => {
  const navData = navdata().props.children
  const selectLayoutState = state => state.Layout
  const selectLayoutProperties = createSelector(selectLayoutState, layout => ({
    leftsidbarSizeType: layout.leftsidbarSizeType,
    sidebarVisibilitytype: layout.sidebarVisibilitytype,
    layoutType: layout.layoutType
  }))

  const { leftsidbarSizeType, sidebarVisibilitytype, layoutType } = useSelector(
    selectLayoutProperties
  )

  const resizeSidebarMenu = useCallback(() => {
    var windowSize = document.documentElement.clientWidth
    if (windowSize >= 1025) {
      if (document.documentElement.getAttribute('data-layout') === 'vertical') {
        document.documentElement.setAttribute(
          'data-sidebar-size',
          leftsidbarSizeType
        )
      }
      if (document.documentElement.getAttribute('data-layout') === 'semibox') {
        document.documentElement.setAttribute(
          'data-sidebar-size',
          leftsidbarSizeType
        )
      }
      if (
        (sidebarVisibilitytype === 'show' ||
          layoutType === 'vertical' ||
          layoutType === 'twocolumn') &&
        document.querySelector('.hamburger-icon')
      ) {
        var hamburgerIcon = document.querySelector('.hamburger-icon')
        if (hamburgerIcon !== null) {
          hamburgerIcon.classList.remove('open')
        }
      } else {
        if (hamburgerIcon !== null) {
          hamburgerIcon.classList.add('open')
        }
      }
    } else if (windowSize < 1025 && windowSize > 767) {
      document.body.classList.remove('twocolumn-panel')
      if (document.documentElement.getAttribute('data-layout') === 'vertical') {
        document.documentElement.setAttribute('data-sidebar-size', 'sm')
      }
      if (document.documentElement.getAttribute('data-layout') === 'semibox') {
        document.documentElement.setAttribute('data-sidebar-size', 'sm')
      }
      if (document.querySelector('.hamburger-icon')) {
        document.querySelector('.hamburger-icon').classList.add('open')
      }
    } else if (windowSize <= 767) {
      document.body.classList.remove('vertical-sidebar-enable')
      if (
        document.documentElement.getAttribute('data-layout') !== 'horizontal'
      ) {
        document.documentElement.setAttribute('data-sidebar-size', 'lg')
      }
      if (document.querySelector('.hamburger-icon')) {
        document.querySelector('.hamburger-icon').classList.add('open')
      }
    }
  }, [leftsidbarSizeType, sidebarVisibilitytype, layoutType])

  useEffect(() => {
    window.addEventListener('resize', resizeSidebarMenu, true)
  }, [resizeSidebarMenu])

  const handleOpenMenu = () => {
    document.documentElement.setAttribute(
      'data-sidebar-size',
      leftsidbarSizeType
    )
  }

  return (
    <React.Fragment>
      {(navData.items || []).map((item, key) => {
        return (
          <React.Fragment key={key}>
            {item['isHeader'] ? (
              <li className="menu-title">
                <span data-key="t-menu">{props.t(item.label)} </span>
              </li>
            ) : (
              <li
                className={
                  navData.currentState === item.id
                    ? 'nav-item menu-icon-selected'
                    : 'nav-item menu-icon-hover'
                }
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (item.subItems) {
                    handleOpenMenu()
                  }
                }}>
                <a
                  href={item.link}
                  style={{
                    color:
                      navData.currentState === item.id ? '#64a0e3' : 'white'
                  }}
                  onClick={e => {
                    e.preventDefault()
                    item.click(e)
                  }}
                  className="nav-link menu-link menu-hover-selected active"
                  data-bs-toggle={
                    item.subItems ? 'collapse' : 'collapseWithoutIcon'
                  }
                  aria-expanded={
                    navData.currentState === item.id ? 'true' : 'false'
                  }>
                  {item.costumIcon ? (
                    <Icon icon={item.costumIcon} size={[17, 17]} />
                  ) : (
                    <i className={item.icon}></i>
                  )}
                  <span
                    style={{ lineHeight: 'normal !important' }}
                    data-key="t-apps">
                    {props.t(item.label)}
                  </span>
                  {item.badgeName ? (
                    <span
                      className={'badge badge-pill bg-' + item.badgeColor}
                      data-key="t-new">
                      {item.badgeName}
                    </span>
                  ) : null}
                </a>
                <Collapse
                  className="menu-dropdown"
                  isOpen={item.stateVariables}
                  id="sidebarApps">
                  <ul className="nav nav-sm flex-column test">
                    {item.subItems &&
                      (item.subItems || []).map((subItem, key) => (
                        <React.Fragment key={key}>
                          <li className="nav-item">
                            <a
                              href={subItem.link}
                              style={{ minWidth: 223 }}
                              onClick={e => {
                                e.preventDefault()
                                subItem.click(e)
                              }}
                              className={
                                navData.subItemCurrentState === subItem.id &&
                                  navData.activeParentId === item.id
                                  ? 'nav-link active'
                                  : 'nav-link'
                              }
                              data-bs-toggle={
                                subItem.childItems
                                  ? 'collapse'
                                  : 'collapseWithoutIcon'
                              }
                              aria-expanded={
                                navData.subItemCurrentState === subItem.id &&
                                  navData.activeParentId === item.id
                                  ? 'true'
                                  : 'false'
                              }>
                              {props.t(subItem.label)}
                              {subItem.badgeName ? (
                                <span
                                  className={
                                    'badge badge-pill bg-' + subItem.badgeColor
                                  }
                                  data-key="t-new">
                                  {subItem.badgeName}
                                </span>
                              ) : null}
                            </a>
                            <Collapse
                              className="menu-dropdown"
                              isOpen={
                                subItem.stateVariables &&
                                navData.activeParentId === item.id
                              }
                              id="sidebarEcommerce">
                              <ul
                                className="nav nav-sm flex-column"
                                style={{
                                  paddingTop: subItem.childItems ? 5 : 0
                                }}>
                                {subItem.childItems &&
                                  subItem.childItems.map((childItem, key) => (
                                    <React.Fragment key={key}>
                                      <li
                                        className="nav-item"
                                        style={{
                                          minWidth: 160
                                        }}>
                                        <a
                                          href={childItem.link}
                                          className={
                                            navData.childItemCurrentState ===
                                              childItem.id &&
                                              navData.activeParentId === item.id
                                              ? 'nav-link active child'
                                              : 'nav-link child'
                                          }
                                          onClick={e => {
                                            e.preventDefault()
                                            childItem.click(e)
                                          }}>
                                          {props.t(childItem.label)}
                                        </a>
                                      </li>
                                    </React.Fragment>
                                  ))}
                              </ul>
                            </Collapse>
                          </li>
                        </React.Fragment>
                      ))}
                  </ul>
                </Collapse>
              </li>
            )}
          </React.Fragment>
        )
      })}
    </React.Fragment>
  )
}

VerticalLayout.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
}

export default withRouter(withTranslation()(VerticalLayout))